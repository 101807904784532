.container-fluid {
    display: none;
}

.ie-error {
    display: block !important;
    width: 100%;
    text-align: center;
    margin-top: 20%;
    background: url('../images/exetel-logo.svg') no-repeat center top;
    padding-top: 150px;
    background-size: 500px;
}